<style scoped src="./index.less" lang="less" rel="stylesheet/less"></style>
<template src="./index.html"></template>
<script>
import mixinPage from "../../libs/mixinPage";
export default {
  mixins: [mixinPage],
  components: {},
  name: "x4",
  data() {
    return {
      pageName:this.$options.name,
      ccysList:[
          {
              icon:require('@/images/cpys/icon_ccys_1.png'),
              t1:"先进的技术",
              t2:"深度融合激光雷达、深度视觉、声呐等感应系统，采用高精度的室内无轨导航技术，实现室内自动规划路线行走。采用独立悬挂系统的移动底盘，具备在室内场景多地形适应能力，可以在光滑石地面、地板、地毯上通行无阻，具有超强的越障能力和避障能力，从而保证运行的稳定性与安全性。",
              img:require('@/images/cpys/img2.png'),
          },
          {
              icon:require('@/images/cpys/icon_ccys_2.png'),
              t1:"实用的需求",
              t2:"产品的设计将速度、避障、稳定性磨炼到极致，将产品的实用性放在首位。最人性化、简易化的操作！从物品摆放到餐车操作，再到接收确认，每一步我们都精心打造，根据实景使用不断优化，真正实现将机械化的工作放心交给了机器做。",
              img:require('@/images/cpys/img3.png'),
          },
           {
              icon:require('@/images/cpys/icon_ccys_3.png'),
              t1:"系统的联动",
              t2:"深度化与管理系统结合，与智能厨控系统联动一体。我们的目标是助力餐饮企业的标准化管理，智能餐车是其中的一个环节，并且这个环节要有效融入整个管理系统，而不仅是一个工具，所以我们致力于将智能餐车与管理系统深度融合，依托LshmisCloud组件，整个生态系统将更智能、更自动化、更标准化。",
              img:require('@/images/cpys/img4.png'),
          },
           {
              icon:require('@/images/cpys/icon_ccys_4.png'),
              t1:"开放的接入",
              t2:"提供识别打印小票自动录入桌台号及菜品功能；并且开放API供接入使用，节省了操作步骤，助力餐饮、娱乐行业的智能化发展。",
              img:require('@/images/cpys/img5.png'),
          },
           {
              icon:require('@/images/cpys/icon_ccys_5.png'),
              t1:"广告的支持",
              t2:"餐车同时具备了广告播放功能，可以将客户的推介菜品滚动播放广告，起到宣传效果。线上、线下全方位广告媒体宣传，全面覆盖中国大陆，扩大品牌影响力。",
              img:require('@/images/cpys/img6.png'),
          },
      ],
      
    };
  },
  methods: {
    
  },
  mounted() {},
  activated() {}
};
</script>



